import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReloadService {
  reload = new BehaviorSubject<boolean>(false);
  reload$ = this.reload.asObservable();
  constructor() {}
}
